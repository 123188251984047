<template>
     <div class="card card-custom">
      <div class="card-body p-0">        
        <div class="row">            
            <div class="col-sm-6 col-xs-12 ">    
              <b-form-group class="mb-3 search-bar">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"                    
                    auto-complete="nope"
                    @keyup.enter.native="(filter)?fetchData(1):''"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                    <i class="fas fa-times" @click="filter =''"></i>
                    <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
       
          <div class="row">
          <div class="col-sm-12">
             <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">              
              <el-table-column label="Credit Memo #" sortable width="150" >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewOrder(scope.row.id)">{{(scope.row.credit_memo_id) ? scope.row.credit_memo_id : ""}}</a></el-tag>                
                </template>
              </el-table-column>             
              <el-table-column label="Customer Name" prop="customer_name" sortable width="200" >
                <template slot-scope="scope">
               {{scope.row.customer?scope.row.customer.first_name+' '+(scope.row.customer.last_name == null?"":scope.row.customer.last_name):""}}
                </template>
              </el-table-column>
               <el-table-column label="Order #" prop="order_ID" sortable width="150" >
                <template slot-scope="scope">
                <span v-if="scope.row.order">{{scope.row.order.order_number}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Reason" prop="cancel_reason" sortable >
                <template slot-scope="scope">
                {{scope.row.reason}} 
                </template>
              </el-table-column> 
               <el-table-column label="Amount" prop="amount" sortable width="150" align="center" >
                <template slot-scope="scope">
                $ {{parseFloat(scope.row.amount).toFixed(2)}} 
                </template>
              </el-table-column> 
               <el-table-column label="Create Date" prop="created_at" sortable width="150" >
                <template slot-scope="scope">
                 {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                </template>
              </el-table-column> 
             
            </el-table>
             <div class="row">
            <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
          </div>
        </div>
      </div>   
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {GetCreditMemo} from "@/api/customer";

export default {
    name: "list-credit_memo",
    data() {
      return {
           loading :false,
           list: [],
          pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },
        filter:null,
      }
    },

     mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Credit Memo", route: "/credit/memo/list" },
      ]);

     },
      methods:{
         fetchData(p) {
            this.loading = true
            let params = { page: p }
            if(this.filter){
              params.filter = this.filter
            }
            if(this.sort){
              params.sort = this.sort;
            }
            if(this.pagination.per_page){
              params.per_page = this.pagination.per_page;
            }
            if(this.selectedWebsite){
              params.selected_website = this.selectedWebsite;
            }
             GetCreditMemo(params).then(response => { 
              this.list = response.data.data.data;       
              this.pagination.current_page = response.data.data.current_page
              this.pagination.last_page = response.data.data.last_page
              this.pagination.total_rows = response.data.data.total
              this.loading = false
              this.$set(this.list, '_showDetails', true)
            });
         },
        
        handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      }, 
              
          } , 
    created(){
      this.fetchData(1);
      
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      },
      
    },
         
}
</script>